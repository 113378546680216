export interface IconProps {
  style?: {};
  className?: string;
}

export { default as Github } from './github';
export { default as Play } from './play';
export { default as Rss } from './rss';
export { default as Twitter } from './twitter';
export { default as Youtube } from './youtube';
