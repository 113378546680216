import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Github, Rss, Twitter, Youtube } from '../icons';
import '../styles/screen.css';

interface Props {
  location: {
    pathname: string;
  };
  title: string;
}

const Layout: React.SFC<Props> = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          siteUrl
          social {
            github
            twitter
            youtube
          }
        }
      }
    }
  `);

  const {
    siteUrl,
    social: { twitter, github, youtube },
  } = data.site.siteMetadata;

  return (
    <div>
      <header className="site-header outer">
        <div className="inner">
          <nav className="site-nav">
            <div className="site-nav-left">
              <Link className="site-nav-logo" to="/">
                {title}
              </Link>
              <ul className="nav" role="menu">
                <li className="nav-about" role="menuitem">
                  <Link to="blog">Blog</Link>
                </li>
                <li className="nav-about" role="menuitem">
                  <Link to="videos">Videos</Link>
                </li>
                <li className="nav-about" role="menuitem">
                  <Link to="about">About</Link>
                </li>
              </ul>
            </div>
            <div className="site-nav-right">
              <div className="social-links">
                {youtube && (
                  <a
                    className="social-link"
                    href={`https://www.youtube.com/channel/${youtube}`}
                    title="YouTube"
                    target="_blank"
                    rel="noopener"
                  >
                    <Youtube />
                  </a>
                )}
                {twitter && (
                  <a
                    className="social-link"
                    href={`https://twitter.com/${twitter}`}
                    title="Twitter"
                    target="_blank"
                    rel="noopener"
                  >
                    <Twitter />
                  </a>
                )}
                {github && (
                  <a
                    className="social-link"
                    href={`https://github.com/${github}`}
                    title="Github"
                    target="_blank"
                    rel="noopener"
                  >
                    <Github />
                  </a>
                )}
              </div>
              <a
                className="rss-button"
                href="/rss.xml"
                title="RSS"
                target="_blank"
                rel="noopener"
              >
                <Rss />
              </a>
            </div>
          </nav>
        </div>
      </header>
      <main>{children}</main>
      <footer className="site-footer outer">
        <div className="site-footer-content inner">
          <section className="copyright">
            <Link to="/">{title} </Link>
            &copy; {new Date().getFullYear()}
          </section>
          <nav className="site-footer-nav">
            <Link to="blog">Latest Posts</Link>
            {github && (
              <a
                href={`https://github.com/${github}`}
                target="_blank"
                rel="noopener"
              >
                Github
              </a>
            )}
            {twitter && (
              <a
                href={`https://twitter.com/${twitter}`}
                target="_blank"
                rel="noopener"
              >
                Twitter
              </a>
            )}
            {youtube && (
              <a
                href={`https://www.youtube.com/channel/${youtube}`}
                target="_blank"
                rel="noopener"
              >
                YouTube
              </a>
            )}
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
