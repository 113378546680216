import React from 'react';

import { IconProps } from './index';

export default ({ className, style }: IconProps) => (
  <svg className={className} viewBox="0 0 200 200" style={style}>
    <circle
      cx="100"
      cy="100"
      r="90"
      fill="none"
      stroke-width="15"
      stroke="#fff"
    />
    <polygon points="70, 55 70, 145 145, 100" fill="#fff" />
  </svg>
);
